import React from 'react'
import Layout from '../components/Layout'

const SubmitStory = () => (
  <Layout>
    <div>
      <h1>Submit a story</h1>
      <p>Coming soon....</p>
    </div>
  </Layout>
)

export default SubmitStory
